.side-menu {
    min-height: 100vh;
	.ant-menu-submenu-title .anticon {
        font-size: 16px;
        margin-right: 5px;
    }
    .ant-menu-submenu-inline {
        .ant-menu-submenu-title {
            white-space: pre-wrap !important;
            height: auto !important;
            line-height: 38px !important;
            font-weight: 700;
            color: @default-black;
            &:hover {
                background-color: @light-yellow
            }
        }
    }
    .ant-menu-sub.ant-menu-inline {
        padding-left: 20px;
        padding-right: 16px;
        background-color: transparent;
    }
    .ant-menu-item {
        padding-left: 10px !important;
        padding-right: 4px !important;
        line-height: 34px !important;
        white-space: pre-wrap !important;
        height: auto !important;
        &::after {
            border-right: none !important;
        }
        &:hover {
            background-color: @light-yellow
        }
    }
    .ant-menu-item-selected .ant-divider {
        opacity: 1;
    }
    .ant-menu-submenu {
        font-weight: 500;
    }
    .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
        width: 6px;
        transform: rotate(-45deg) translateX(-3px);
        -webkit-transform: rotate(-45deg) translateX(-3px);
    }
    .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
        width: 6px;
        transform: rotate(45deg) translateX(3px);
        -webkit-transform: rotate(45deg) translateX(3px);
    }
    .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
        width: 6px;
        transform: rotate(45deg) translateX(-3px);
        -webkit-transform: rotate(45deg) translateX(-3px);
    }
    .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
        width: 6px;
        transform: rotate(-45deg) translateX(3px);
        -webkit-transform: rotate(-45deg) translateX(3px);
    }
    .ant-divider {
        width: 4px;
        margin: 0;
        top: 20%;
        height: 60%;
        border-left: 0;
        float: right;
        border-radius: 2px;
        background: @main-yellow;
        opacity: 0;
    }
    .ant-menu-item-selected a, .ant-menu-item a:hover {
        color: @default-black;
    }
    .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
        background-image: linear-gradient(to right, rgba(35, 31, 32, 0.85), rgba(35, 31, 32, 0.85));
    }
    .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
        background-image: linear-gradient(to right, rgba(35, 31, 32, 0.85), rgba(35, 31, 32, 0.85));
    }
    .ant-menu-inline .ant-menu-item:not(:last-child) {
        margin-bottom: 4px;
    }
}

.anticon.trigger {
    margin-top:36px;
    font-size: 12px;
    width: 40px;
    min-width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-style: normal;
    font-weight: 400;
    background-color: white;
    border-radius: 0px 2px 2px 0px;
    color: #000;
    & svg {
        width: 12px;
        height: 12px;
    }
}
