div.ant-modal-confirm {
	.ant-modal-content {
		border-radius: 8px;
	}
	.ant-modal-body {
		padding: 20px 15px 15px 15px !important;
		.ant-modal-confirm-content {
			margin-top: 0px;
		}
	}
	.ant-modal-confirm-btns {
		float: unset;
		margin-top: 15px;
	}
}