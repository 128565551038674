/* Regular 400 */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('~@styles/fonts/Sarabun/Sarabun-Regular.ttf') format('truetype');
  }
/* Medium 500*/
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('~@styles/fonts/Sarabun/Sarabun-Medium.ttf') format('truetype');
}
/* Semi-bold */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('~@styles/fonts/Sarabun/Sarabun-SemiBold.ttf') format('truetype');
}
/* Bold */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('~@styles/fonts/Sarabun/Sarabun-Bold.ttf') format('truetype');
}
/* Extra-bold */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('~@styles/fonts/Sarabun/Sarabun-ExtraBold.ttf') format('truetype');
}