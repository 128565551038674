.ant-input-affix-wrapper, .ant-select, .ant-input-number-input-wrap {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	margin-bottom: 2px !important;
	&:focus-within {
		border-bottom: 2px solid @main-yellow;
		margin-bottom: 0px !important;
		&:not(.ant-select) {
			margin-bottom: 1px !important;
		}
	}
}

.ant-input-search-button {
	margin-bottom: 2px;
}

.ant-form-item-control-input {
    .ant-input {
		padding-bottom: 2px !important;
        &:focus-within {
            border-bottom: 2px solid @main-yellow;
			margin-bottom: 0px !important;
        }
    }
}

.ant-form-item-control-input, .search-form {
    .ant-input-group {
        .ant-input {
            &:focus-within {
                border-bottom: none;
            }
        }
    }
}

.ant-input-number {
	border: 1px solid #00000015 !important;
	.ant-input-number-input-wrap {
		margin-bottom: 2px;
		&:focus-within {
			border-bottom: 2px solid @main-yellow;
			margin-bottom: 0px !important;
		}
	}
}